import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["count", "price"]
  static values = { id: String }

  connect() {
    const option = document.querySelector('[data-controller="booking--addons"]').querySelector(`option[data-id="${this.idValue}"]`)
    const count = option.value.split('-')[1] || 0

    this.countTarget.textContent = count
    this.updatePrice(count)

    this.element.addEventListener('click', (e) => {
      if(parseInt(this.countTarget.textContent) == 0) {
        this.increment(e)
      } else {
        e.preventDefault()
        e.stopPropagation()
      }
    })
  }

  disconnect() {
    this.element.removeEventListener('click', (e) => {
      e.preventDefault()
      e.stopPropagation()
    })
  }

  increment(event) {
    event.preventDefault()
    event.stopPropagation()

    const current = parseInt(this.countTarget.textContent)
    this.countTarget.textContent = current + 1
    this.updatePrice(current + 1)
    this.updateOptionValue(current + 1)
  }

  decrement(event) {
    event.preventDefault()
    event.stopPropagation()

    const current = parseInt(this.countTarget.textContent)
    if (current > 0) {
      this.countTarget.textContent = current - 1
      this.updatePrice(current - 1)
      this.updateOptionValue(current - 1)
    }
  }

  updateOptionValue(count) {
    const select = $('[data-controller="booking--addons"]')
    const option = select.find(`option[data-id="${this.idValue}"]`)

    option.val(`${this.idValue}-${count}`)

    if(count == 0) {
      option.removeAttr('selected')
    } else {
      option.attr('selected', 'selected')
    }
    $(select).selectpicker('refresh')
  }

  updatePrice(count) {
    if(count > 0) {
      this.priceTarget.textContent = `+${this.priceTarget.dataset.price} x ${count}`
      this.priceTarget.classList.add('text-success')
    }
    else {
      this.priceTarget.textContent = this.priceTarget.dataset.price
      this.priceTarget.classList.remove('text-success')
    }
  }
}
