import { Controller } from "@hotwired/stimulus"
import { ImageCompareWithCrop } from './image_compare_with_crop';

// depends on following partials:
// - app/views/gallery_images/sidepage/_before_after_form.slim
// - app/views/gallery_images/sidepage/_before_after_image.slim

export default class extends Controller {
  static targets = ['beforeImageId', 'beforeCropData', 'afterImageId', 'afterCropData', 'previewComponent']

  connect() {
    this.previewElement = this.element.querySelector("#before-after-image-preview");
    if(this.beforeCropDataTarget.value) {
      this.beforeCropData = JSON.parse(this.beforeCropDataTarget.value)
    }
    this.beforeCropData ||= {}
    if(this.afterCropDataTarget.value) {
      this.afterCropData = JSON.parse(this.afterCropDataTarget.value)
    }
    this.afterCropData ||= {}

    this.bindPreviewSection()
    this.bindUploadEvents()
    this.bindSelectEvents()
  }

  imageItemUrl(element) {
    if(!element || !element.closest(".image-item")){
      return null
    }

    return element.closest(".image-item").querySelector("input.full-size-url").value
  }

  imageItemBlobId(element) {
    if(!element || !element.closest(".image-item")){
      return null
    }

    return element.closest(".image-item").querySelector("input.blob-id").value
  }

  selectImage(event) {
    if(event.target.closest(".gallery-images.images-before")) {
      this.handleSelectImageBefore(event)
    } else {
      this.handleSelectImageAfter(event)
    }
  }

  selectImageBefore(element){
    const url = this.imageItemUrl(element)
    if(!url) {
      return
    }

    this.setBeforeImageUrl(url)
    this.setBeforeImageBlobId(this.imageItemBlobId(element))
    this.markSelectedImage(this.beforeImagesList(), this.beforeImageIdTarget.value, element)
    this.markUsedImage(this.afterImagesList(), this.beforeImageIdTarget.value)
  }

  handleSelectImageBefore(event) {
    this.selectImageBefore(event.target)
  }

  selectImageAfter(element){
    const url = this.imageItemUrl(element)
    if(!url) {
      return
    }

    this.setAfterImageUrl(url)
    this.setAfterImageBlobId(this.imageItemBlobId(element))
    this.markSelectedImage(this.afterImagesList(), this.afterImageIdTarget.value, element)
    this.markUsedImage(this.beforeImagesList(), this.afterImageIdTarget.value)
  }

  handleSelectImageAfter(event) {
    this.selectImageAfter(event.target)
  }

  beforeImagesList() {
    return this.element.querySelectorAll(".gallery-images.images-before .image-item")
  }

  afterImagesList() {
    return this.element.querySelectorAll(".gallery-images.images-after .image-item")
  }

  bindUploadEvents() {
    $(document).off("uploads:attached-to-parent.before_after_controller")
    $(document).on("uploads:attached-to-parent.before_after_controller", this.handleUpload.bind(this))
  }


  initialSelectionForBefore() {
    if(this.beforeImagesList().length >= 2) {
      if(!this.beforeImageIdTarget.value) {
        this.setBeforeImageBlobId(this.imageItemBlobId(this.beforeImagesList()[0]))
      }
      this.markSelectedImage(this.beforeImagesList(), this.beforeImageIdTarget.value)
      this.markUsedImage(this.afterImagesList(), this.beforeImageIdTarget.value)
      this.setBeforeImageUrl(this.imageUrlById(this.beforeImagesList(), this.beforeImageIdTarget.value))
    }
  }

  initialSelectionForAfter() {
    if(this.afterImagesList().length >= 2) {
      if(!this.afterImageIdTarget.value) {
        this.setAfterImageBlobId(this.imageItemBlobId(this.afterImagesList()[1]))
      }
      this.markSelectedImage(this.afterImagesList(), this.afterImageIdTarget.value)
      this.markUsedImage(this.beforeImagesList(), this.afterImageIdTarget.value)
      this.setAfterImageUrl(this.imageUrlById(this.afterImagesList(), this.afterImageIdTarget.value))
    }
  }

  bindSelectEvents() {
    var self = this
    // todo: use stimulus actions for clicks
    this.beforeImagesList().forEach(function (item) {
      item.addEventListener('click', self.handleSelectImageBefore.bind(self))
    })
    this.afterImagesList().forEach(function (item) {
      item.addEventListener('click', self.handleSelectImageAfter.bind(self))
    })
  }

  panAndZoomChangesCallback(data){
    this.beforeCropData = {x: data.before.x, y: data.before.y, scale: data.before.scale, splitPos: data.before.splitPos}
    this.beforeCropDataTarget.value = JSON.stringify(this.beforeCropData)
    this.afterCropData = {x: data.after.x, y: data.after.y, scale: data.after.scale}
    this.afterCropDataTarget.value = JSON.stringify(this.afterCropData)
  }

  bindPreviewSection() {
    this.viewer = ImageCompareWithCrop(this.previewElement, {
        splitPos: this.beforeCropData.splitPos,
        beforeX: this.beforeCropData.x,
        beforeY: this.beforeCropData.y,
        beforeS: this.beforeCropData.scale,
        afterX: this.afterCropData.x,
        afterY: this.afterCropData.y,
        afterS: this.afterCropData.scale,
        editor: true
        // note: size of real images is determined after load
      },
      this.panAndZoomChangesCallback.bind(this),
    );

    this.initialSelectionForBefore()
    this.initialSelectionForAfter()
  }

  imageUrlById(imagesList, blobId) {
    for (let i = 0; i < imagesList.length; i++) {
      const item = imagesList[i];
      if(item.querySelector("input.blob-id").value === blobId) {
        return this.imageItemUrl(item);
      }
    }
    return null;
  }

  queryForUploadedItems(ids) {
    let self = this
    ids.forEach(function (id) {
      $.ajax("/gallery_images/" + id + "/render_before_after_item", {
        dataType: 'html',
        headers: {
          'X-Modal': '1'
        }
      }).done((html, status, xhr) => {
        if (status == 'success') {
          $('.gallery-images.images-before').append(html)
          $('.gallery-images.images-after').append(html)
          self.initialSelectionForBefore()
          self.initialSelectionForAfter()
        }
      }).fail((xhr) => {
        Notify.error('Something went wrong, try again later');
      })
    })
  }

  handleUpload(event) {
    this.queryForUploadedItems(event.detail.ids)
  }

  setBeforeImageUrl(url) {
    let imageItem = this.previewElement.querySelector(".image-before")
    imageItem.classList.remove("empty")
    imageItem.src = url
  }

  setAfterImageUrl(url) {
    let imageItem = this.previewElement.querySelector(".image-after")
    imageItem.classList.remove("empty")
    imageItem.src = url
  }

  setBeforeImageBlobId(blobId) {
    this.beforeImageIdTarget.value = blobId
  }

  setAfterImageBlobId(blobId) {
    this.afterImageIdTarget.value = blobId
  }

  markUsedImage(imagesList, usedId) {
    let selected = false
    imagesList.forEach(function (item) {
      if(item.querySelector("input.blob-id").value == usedId && !selected) {
        item.classList.add("used")
        item.dataset.tooltipTextValue = "You can't select this image, it's used on opposite side"
        selected = true
      } else {
        item.classList.remove("used")
        item.dataset.tooltipTextValue = item.dataset['tooltipTextDefaultValue']
      }
    })
  }

  markSelectedImage(imagesList, selectedId, element) {
    // manual selection
    if(element) {
      $(element).closest('.gallery-images').find('.image-item').removeClass('selected')
      $(element).closest('.image-item').addClass('selected')
      return
    }

    // initial auto selection, check only first image for case where image blob id is not unique
    let selected = false
    imagesList.forEach(function (item) {
      if(item.querySelector("input.blob-id").value == selectedId && !selected) {
        item.classList.toggle('selected')
        selected = true
      }
    })
  }

  disconnect() {
    var self = this
    this.element.querySelectorAll(".gallery-images.images-before .image-item").forEach(function (item) {
      item.removeEventListener('click', self.handleSelectImageBefore.bind(self))
    })
    this.element.querySelectorAll(".gallery-images.images-after .image-item").forEach(function (item) {
      item.removeEventListener('click', self.handleSelectImageAfter.bind(self))
    })
  }
}
