import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    // prevent double initialization controller
    // selectize clone html with attached controller
    if(document.querySelector(`#${this.element.id}-selectized`)?.selectize) return;

    let selectOptions = {
      persist: false,
      closeAfterSelect: true,
      dropdownParent: null,
      onInitialize: function () {
        var s = this;
        this.revertSettings.$children.each(function () {
          if (this.tagName == "OPTION")
            $.extend(s.options[this.value], $(this).data());
          else if (this.tagName == "OPTGROUP") {
            $(this.children).each(function () {
              $.extend(s.options[this.value], $(this).data());
              s.options[this.value].data = $(this).data();
            });
          }
        });
      },
      render: {
        option: function (item, escape) {
          return `<div class="option">${JSON.parse(item.content)}</div>`;
        },
      },

      onChange: (value) => {
        let options = { value: value };
        const event = new CustomEvent("change", { detail: options });
        this.element.dispatchEvent(event);
      },
    };

    if($(this.element).attr('multiple') == 'multiple' ) {
      selectOptions.maxItems = null
      selectOptions.plugins = ['remove_button']
    } else {
      selectOptions.maxItems = 1
    }

    $(this.element).selectize(selectOptions);
  }
}
