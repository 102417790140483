import { Controller } from "@hotwired/stimulus";

// controller closes modal menu when target is clicked
export default class extends Controller {
  static targets = ["btn"]
  static values = {
    delay: Number
  }

  connect() {
    this.btnTargets.forEach(item =>
      item.addEventListener("click", item.boundHide = this.hide.bind(this))
    )
  }

  disconnect() {
    this.btnTargets.forEach(item =>
      item.removeEventListener("click", item.boundHide)
    )
  }

  hide() {
    if (this.delayValue) setTimeout(() => $(this.element).modal('hide'), this.delayValue);
    else $(this.element).modal('hide')
  }
}