import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    amount: {type: Number, default: 0.0},
    currency: { type: String, default: 'USD' }
  }

  static targets = ["total"]

  initialize() {
    this.currencyFormatter = Intl.NumberFormat('en', {style: 'currency', currency: this.currencyValue})

    let $element = $(this.element)
    $element.on('change', "input[type='radio']", this.calculateTotal.bind(this))

    this.$customTipElm = $('input[name*="tip_custom_amount"]')
    $element.on('keyup', this.$customTipElm, this.calculateTotal.bind(this))
  }

  calculateTotal(event) {
    let total = this.amountValue

    let $options = $(this.element).find("input[type='radio']")
    let $checked = $options.filter(':checked')

    if( $checked ) {
      let tipAmount = 0

      let checkedOption = $checked.val()
      if(checkedOption == 'custom') {
        tipAmount = parseFloat(this.$customTipElm.val().replaceAll(',', ''))
        if( isNaN(tipAmount) ) tipAmount = 0
      } else if(checkedOption == 0) {
        tipAmount = 0
      } else {
        tipAmount = parseFloat($checked.data('amount'))
      }

      total += tipAmount
    }

    this.totalTarget.innerHTML = this.currencyFormatter.format(total)
  }
}
