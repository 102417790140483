import { Controller } from "@hotwired/stimulus"

// If need to add some custom block of html to ignore list -> add class '.slide-menu--ignore-list-item' to block
export default class extends Controller {
  static values = {
    ignoreList: String
  }

  connect() {
    setTimeout(() => { // BUGFIX: connect is called before turbo completes its work, wait a tick before initializing slide menu controller
      this.boundOnResize = this.onResize.bind(this)
      this.boundLeftMenuButtonClick = this.handleLeftMenuButtonClick.bind(this)
      this.leftMenuButton = this.element.querySelector('#btnLeftMenu')
      window.addEventListener('resize', this.boundOnResize)
      this.leftMenuButton?.addEventListener('click', this.boundLeftMenuButtonClick)
      window.addEventListener('click', this.boundHandleHide)

      // don't use collapsed-menu if < 720, always add collapsed-menu if in 720..1200 range, use cookies setting for collapsed-menu if > 1200
      const isCollapsed = window.innerWidth > 720 && (window.innerWidth <= 1200 || !window.location.href.includes('hub') && getCookie('menu_collapsed') === '1');
      document.body.classList.toggle('collapsed-menu', isCollapsed)
      this.updateAllTooltips(!isCollapsed)
    });
  }

  // #btnLeftMenu (adds .collapsed-menu) conflicts with toggle-menu-button (adds .open to .slide-menu) at widths <= 1200
  // fix this by listening to window size change and hiding #leftMenuButton if width drops below 721

  onResize() {
    if (window.innerWidth <= 720) {
      document.body.classList.remove('collapsed-menu');
    }
    else if (window.innerWidth <= 1200) {
      this.element.classList.remove('open')
      document.body.classList.remove('show-organizations-menu')
      if (!window.location.href.includes('hub')) {
        setCookie('menu_collapsed', '', -1) // delete cookie
      }
    }
    this.updateAllTooltips();
  }

  // This will collapse sidebar menu on left into a mini icon menu
  handleLeftMenuButtonClick(e) {
    e.preventDefault()
    const isCollapsed = document.body.classList.toggle('collapsed-menu');
    this.element.classList.toggle('open', !isCollapsed);
    if (window.innerWidth > 1200) {
      if (!window.location.href.includes('hub')) {
        if(isCollapsed) {
          setCookie('menu_collapsed', '1', 30)  // activated for 30 days
        }
        else {
          setCookie('menu_collapsed', '', -1) // delete cookie
        }
      }
    }
    else if(!isCollapsed) { // add click outside menu to close it if screen < 1200
      window.addEventListener('click', this.boundHandleHide = this.handleHide.bind(this))
    }
    document.body.classList.remove('show-organizations-menu')
    this.updateAllTooltips()
  }

  toggleOpen() {
    const isOpen = this.element.classList.toggle('open')
    if (isOpen) {
      this.boundHandleHide = this.handleHide.bind(this)
      window.addEventListener('click', this.boundHandleHide)
      document.body.classList.remove('collapsed-menu');
    }
  }

  disconnect() {
    window.removeEventListener('click', this.boundHandleHide)
    window.removeEventListener('resize', this.boundOnResize)
    this.leftMenuButton?.removeEventListener('click', this.boundLeftMenuButtonClick)
  }

  handleHide(e) {
    if (window.innerWidth <= 1200 & this.element.querySelector('.menu-left').classList.contains('is-editing'))
      return;

    // prevent click for some classes where window width less then 720px
    const ignoreList = this.ignoreListValue.split(',').filter(Boolean).concat([
      '.column-list > .column-header.header-primary',
      '.column-list > .column-sub-header',
      '.messages-column-left .column-header.header-primary',
      '.messages-column.messages-column-contacts .column-sub-header',
      '.column-settings-menu .sub-menu-group--title'
    ])
    if (ignoreList.some(id => e.target.closest(id)) && window.innerWidth <= 720) return;
    if (!this.element.contains(e.target)) { // click outside menu
      this.element.classList.remove('open')
      document.body.classList.remove('show-organizations-menu')
      if (window.innerWidth >= 720)
        document.body.classList.add('collapsed-menu');
      window.removeEventListener('click', this.boundHandleHide)
      this.updateAllTooltips()
    }
  }

  updateAllTooltips() {
    const enabled = document.body.classList.contains('collapsed-menu') || (window.innerWidth <= 1200 && !this.element.classList.contains('open'));
    this.element.querySelectorAll('[data-controller="tooltip"]').forEach(menuEl => {
      const controller = this.application.getControllerForElementAndIdentifier(menuEl, "tooltip")
      if (controller && controller.element._tippy) {
        controller.element._tippy[enabled ? 'enable' : 'disable']();
      }
    })
  }
}