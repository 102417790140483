const MIN_SCALE = 1;
const MAX_SCALE = 6;


export function ImageCompareWithCrop(container, {
  // absolute x, y is available initially
  // rx, ry, width, height - available after
    splitPos = 50,
    beforeX = 0,
    beforeY = 0,
    beforeS = 1,
    afterX = 0,
    afterY = 0,
    afterS = 1,
    editor = true
  } = {},
  onChangeCallback = null
) {
  let bscale = beforeS;
  let ascale = afterS;
  let brx = 0;
  let bry = 0;
  let arx = 0;
  let ary = 0;

  // init component

  container.classList.add('image_crop_container');

  const afterContainer = container.appendChild(document.createElement('div'));
  afterContainer.classList.add('afterContainer');

  const handle = container.appendChild(document.createElement('div'));
  handle.classList.add('handle');
  handle.style.left = splitPos + '%';

  const imageBefore = container.querySelector('.image-before');
  const imageAfter = afterContainer.appendChild(container.querySelector('.image-after'));

  let beforeImageSize = null;
  let afterImageSize = null;

  function imageLoaded() {
    beforeImageSize = { width: imageBefore.naturalWidth, height: imageBefore.naturalHeight };
    afterImageSize = { width: imageAfter.naturalWidth, height: imageAfter.naturalHeight };

    // basic images setup
    [brx, bry] = scaleImage(imageBefore, 0, 0, 0, 0, 1.0, 1.0);
    [arx, ary] = scaleImage(imageAfter, 0, 0, 0, 0, 1.0, 1.0);

    brx = beforeX / beforeImageSize.width;
    bry = beforeY / beforeImageSize.height;
    arx = afterX / afterImageSize.width;
    ary = afterY / afterImageSize.height;

    // initial images positioning
    [brx, bry] = scaleImage(imageBefore, 0, 0, brx, bry, 1.0, bscale);
    [arx, ary] = scaleImage(imageAfter, 0, 0, arx, ary, 1.0, ascale);

    updateSplit();
    onChangeCallback?.(getState());
  }

  imageBefore.addEventListener('load', () => imageLoaded())
  imageAfter.addEventListener('load', () => imageLoaded())

  // split positioning
  handle.addEventListener('pointerdown', e => {
    const containerRect = container.getBoundingClientRect();
    const dx = handle.offsetWidth / 2 - e.offsetX;
    document.body.classList.add('dragging');

    e.preventDefault();
    handle.setPointerCapture(e.pointerId);
    handle.addEventListener('pointerup', ue => {
      handle.releasePointerCapture(ue.pointerId);
      handle.removeEventListener('pointermove', handleMove);
      document.body.classList.remove('dragging');
    }, { once: true });
    handle.addEventListener('pointermove', handleMove);

    function handleMove(me) {
      splitPos = Math.max(0, Math.min(100, 100 - (me.clientX - containerRect.x + dx) / containerRect.width * 100));
      updateSplit();
    }
  });

  function updateSplit() {
    afterContainer.style.width = splitPos + '%';
    handle.style.left = (100 - splitPos) + '%';
    if (editor) onChangeCallback?.(getState());
  }

  if(editor) {
    // images pan
    container.addEventListener('pointerdown', de => {
      de.preventDefault();
      const target = de.target;

      if (![imageBefore, imageAfter].includes(target)) return;

      target.setPointerCapture(de.pointerId);

      container.addEventListener('pointermove', handleMove);
      const sbrx = brx; // x start before image relational
      const sbry = bry; // y start before image relational
      const sarx = arx; // x start after image relational
      const sary = ary; // y start after image relational
      function handleMove(me) {
        if (target === imageBefore) {
          brx = sbrx + (me.x - de.x) / container.offsetWidth;
          bry = sbry + (me.y - de.y) / container.offsetHeight;
          panImage(imageBefore, brx, bry);
        }
        else {
          const k = imageAfter.naturalWidth / imageAfter.naturalHeight;
          const afterWidth = container.offsetHeight * k;
          arx = sarx + (me.x - de.x) / afterWidth;
          ary = sary + (me.y - de.y) / container.offsetHeight;
          panImage(imageAfter, arx, ary);
        }
        onChangeCallback?.(getState());
      }
      container.addEventListener('pointerup', ue => {
        container.removeEventListener('pointermove', handleMove);
        target.releasePointerCapture(ue.pointerId);
      }, { once: true });
    });

    // images scaling
    container.addEventListener('wheel', e => {
      e.preventDefault();
      const containerRect = container.getBoundingClientRect();

      if (e.target === imageBefore) {
        const mx = e.clientX - containerRect.x; // mouse x offset in image
        const my = e.clientY - containerRect.y; // mouse y offset in image
        const rmx = mx / containerRect.width;
        const rmy = my / containerRect.height;

        const newBscale = Math.min(Math.max(MIN_SCALE, bscale + e.deltaY * -0.01), MAX_SCALE);
        [brx, bry] = scaleImage(imageBefore, rmx, rmy, brx, bry, bscale, newBscale);
        bscale = newBscale;
      }
      else if (e.target === imageAfter) {
        const k = imageAfter.naturalWidth / imageAfter.naturalHeight;
        const afterWidth = containerRect.height * k;
        const mx = e.clientX - containerRect.x - (containerRect.width - afterWidth); // mouse x offset in image
        const my = e.clientY - containerRect.y; // mouse y offset in image
        const rmx = mx / afterWidth;
        const rmy = my / containerRect.height;

        const newAscale = Math.min(Math.max(MIN_SCALE, ascale + e.deltaY * -0.01), MAX_SCALE);
        [arx, ary] = scaleImage(imageAfter, rmx, rmy, arx, ary, ascale, newAscale);
        ascale = newAscale;
      }
      onChangeCallback?.(getState());
    });
  }

  function scaleImage(image, rmx, rmy, rx, ry, scale, newScale) {
    const k = (newScale / scale - 1);
    rx += (rx - rmx) * k;
    ry += (ry - rmy) * k;
    image.style.scale = newScale;
    panImage(image, rx, ry);

    return [rx, ry];
  }

  function panImage(image, rx, ry) {
    const k = (image.style.scale - 1) / 2;
    image.style.translate = `${(k + rx) * 100}% ${(k + ry) * 100}%`;
  }

  function getState() {
    const data = {
      before: {
        splitPos,
        scale: bscale,
        x: beforeImageSize.width*brx/bscale,
        y: beforeImageSize.height*bry/bscale
      },
      after: {
        scale: ascale,
        x: afterImageSize.width*arx/ascale,
        y: afterImageSize.height*ary/ascale
      },
    }
    return data
  }

  return { onChangeCallback, getState }
}

// figures for calculations: https://drive.google.com/file/d/1SjA6asmK6cCkQnD6H0wigN5HTpE_Q5jq/view?usp=sharing

// const tx = (.5 + rx / (scale - 1)) * 100 * (scale - 1);
// const ty = (.5 + ry / (scale - 1)) * 100 * (scale - 1);

//     // images scaling
//     let bscale = 1; // "before" image scale
//     let ascale = 1; // "after" image scale
//     let rx = 0;
//     let ry = 0;
//     const MIN_SCALE = 1;
//     const MAX_SCALE = 6;
//     container.addEventListener('wheel', e => {
//         e.preventDefault();
//         const newBscale = Math.min(Math.max(MIN_SCALE, bscale + e.deltaY * -0.01), MAX_SCALE);
// ``
//         const containerRect = container.getBoundingClientRect();
//         const mx = e.clientX - containerRect.x; // mouse x offset in container
//         const my = e.clientY - containerRect.y; // mouse y offset in container
//         const rmx = mx / containerRect.width;
//         const rmy = my / containerRect.height;
//         const k1 = (newBscale / bscale - 1);
//         rx += (rx - rmx) * k1;
//         ry += (ry - rmy) * k1;

//         imageBefore.style.scale = newBscale;
//         // const tx = (.5 + rx / (newBscale - 1)) * 100 * (newBscale - 1);
//         // const ty = (.5 + ry / (newBscale - 1)) * 100 * (newBscale - 1);
//         const k2 = (newBscale - 1) / 2;
//         const tx = k2 + rx;
//         const ty = k2 + ry;
//         imageBefore.style.translate = `${tx * 100}% ${ty * 100}%`;
//         bscale = newBscale;
//     });
