import { Controller } from "@hotwired/stimulus";
import Quill from "quill";

export default class extends Controller {
  static targets = [
    "embedWidget",
    "slug",
    "accentColor",
    "backgroundColor",
    "textColor",
    "accentTextColor",
    "headerBgColor",
    "cardBgColor",
    "enabledServiceImages",
    // "enabledServiceImagesTabs",
    "enabledServiceImagesNoImage",
    "enabledWidgetHeader",
    "enabledAddress",
    "enabledCustomerTimezone",
    "enabledSelectEmployees",
    "enabledBackgroundImage",
    "enabledSafetyInfo",
    "safetyInfoEditor",
    "safetyInfo",
    "enabledTermsOfService",
    "termsOfServiceEditor",
    "termsOfService",
    "enabledServices",
    "enabledClasses",
    "enabledCategories",
    "serviceThumbTypeFill",
    "serviceThumbTypeLimit",
    "previewBookingPageIframe",
    "linkToBookingPage",
    "cardsPerRow",
    "managedIframeHeight"
  ];

  connect() {
    this.defaultUrl = this.element.dataset.defaultUrl;
    this.template = this.element.dataset.template;

    if (this.isSavedBookingPage()) {
      this.defaultPreviewBookingPageURL =
        this.previewBookingPageIframeTarget.getAttribute("src");
    }

    if (this.hasSafetyInfoEditorTarget) {
      this.safetyInfoEditor = new Quill(this.safetyInfoEditorTarget, {
        modules: { toolbar: true },
        theme: "snow",
      });

      this.safetyInfoEditor.on(
        "text-change",
        () =>
          (this.safetyInfoTarget.value = this.safetyInfoEditor.root.innerHTML)
      );
    }

    if (this.hasTermsOfServiceEditorTarget) {
      this.termsOfServiceEditor = new Quill(this.termsOfServiceEditorTarget, {
        modules: { toolbar: true },
        theme: "snow",
      });

      this.termsOfServiceEditor.on(
        "text-change",
        () =>
          (this.termsOfServiceTarget.value = this.termsOfServiceEditor.root.innerHTML)
      );
    }

    this.updateWidgetCode();
  }

  updateWidgetCode() {
    let url = `${this.defaultUrl}${this.slugTarget.value}`;

    this.embedWidgetTarget.value = this.template.replace("$URL", url);
    this.linkToBookingPageTarget.setAttribute("href", url);
    this.slugTarget.parentElement.dataset.inputCopyText = url;
  }

  refreshBookingPagePreview() {
    if (!this.isSavedBookingPage()) return;

    let query = new URLSearchParams({
      bg_color: this.backgroundColorTarget.value,
      text_color: this.textColorTarget.value,
      accent_color: this.accentColorTarget.value,
      header_bg_color: this.headerBgColorTarget.value,
      card_bg_color: this.cardBgColorTarget.value,
      accent_text_color: this.accentTextColorTarget.value,
      service_images: this.enabledServiceImagesTarget.checked,
      // service_images_tabs: this.enabledServiceImagesTabsTarget.checked,
      service_images_no_image: this.enabledServiceImagesNoImageTarget.checked,
      widget_header: this.enabledWidgetHeaderTarget.checked,
      enabled_address: this.enabledAddressTarget.checked,
      customer_timezone: this.enabledCustomerTimezoneTarget.checked,
      select_employees: this.enabledSelectEmployeesTarget.checked,
      enabled_bg_image: this.enabledBackgroundImageTarget.checked,
      enabled_safety_info: this.enabledSafetyInfoTarget.checked,
      enabled_services: this.enabledServicesTarget.checked,
      enabled_classes: this.enabledClassesTarget.checked,
      service_thumb_type: this.serviceThumbTypeFillTarget.checked ? this.serviceThumbTypeFillTarget.value : this.serviceThumbTypeLimitTarget.value,
      cards_per_row: this.cardsPerRowTarget.value,
      enabled_categories: this.enabledCategoriesTarget.checked,
      managed_iframe_height: this.managedIframeHeightTarget.checked
    });

    let src = `${this.defaultPreviewBookingPageURL}?` + query.toString();
    this.previewBookingPageIframeTarget.setAttribute("src", src);
  }

  resetToDefault() {
    this.backgroundColorTarget.value = this.backgroundColorTarget.parentNode.parentNode.querySelector(':scope>input').value = this.backgroundColorTarget.dataset.defaultColor;
    this.textColorTarget.value = this.textColorTarget.parentNode.parentNode.querySelector(':scope>input').value = this.textColorTarget.dataset.defaultColor;
    this.accentColorTarget.value = this.accentColorTarget.parentNode.parentNode.querySelector(':scope>input').value = this.accentColorTarget.dataset.defaultColor;
    this.headerBgColorTarget.value = this.headerBgColorTarget.parentNode.parentNode.querySelector(':scope>input').value = this.headerBgColorTarget.dataset.defaultColor;
    this.cardBgColorTarget.value = this.cardBgColorTarget.parentNode.parentNode.querySelector(':scope>input').value = this.cardBgColorTarget.dataset.defaultColor;
    this.accentTextColorTarget.value = this.accentTextColorTarget.parentNode.parentNode.querySelector(':scope>input').value = this.accentTextColorTarget.dataset.defaultColor;

    this.enabledBackgroundImageTarget.checked = true;
    document
      .querySelector("[data-controller=single-image-upload]")
      ["single-image-upload"].remove();

    this.refreshBookingPagePreview();
  }

  isSavedBookingPage() {
    return this.hasPreviewBookingPageIframeTarget;
  }
}
