import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"
import { ImageCompareWithCrop } from './galleries/image_compare_with_crop';

export default class extends Controller {
  static targets = ['previewComponent']


  connect() {
    this.beforeCropData = JSON.parse(this.element.dataset.cropBefore)
    this.beforeCropData ||= {}
    this.afterCropData = JSON.parse(this.element.dataset.cropAfter)
    this.afterCropData ||= {}
    this.bindPreviewSection()
  }

  bindPreviewSection() {
    this.viewer = ImageCompareWithCrop(
        this.previewComponentTarget, {
          splitPos: this.beforeCropData.splitPos,
          beforeX: this.beforeCropData.x,
          beforeY: this.beforeCropData.y,
          beforeS: this.beforeCropData.scale,
          afterX: this.afterCropData.x,
          afterY: this.afterCropData.y,
          afterS: this.afterCropData.scale,
          editor: false
        // note: size of real images is determined after load
        },
        null
    );
  }
}
