import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["count"]
  static values = { count: Number, default: 1 }

  connect() {
    console.log('connected')
    this.countValue = this.default
  }

  decrement(e) {
    console.log('decrement')
    e.preventDefault()

    this.countValue = (this.countValue || 1) - 1;
    this.countTarget.innerHTML = this.countValue;
    return false
  }

  increment(e) {
    console.log('increment')
    e.preventDefault()

    this.countValue = (this.countValue || 1) + 1;
    this.countTarget.innerHTML = this.countValue;

    return false
  }
}
