export function trackHeightChanges(element, callback) {
  let previousHeight = Math.ceil(element.getBoundingClientRect().height);
  // save start height
  callback(previousHeight);
  // checking height changes function
  function checkHeightChange() {
    const currentHeight = Math.ceil(element.getBoundingClientRect().height);
    if (!currentHeight) return;
    if (previousHeight !== currentHeight) {
      previousHeight = currentHeight;
      callback(currentHeight); // call callback with new height
    }
  }

  // create listener for element's size changes
  const resizeObserver = new ResizeObserver(checkHeightChange);
  resizeObserver.observe(element);

  // create listener for element's attributes and style changes
  const mutationObserver = new MutationObserver((mutationsList) => {
    mutationsList.forEach(mutationRecord => {
      if (
        mutationRecord.type === "attributes" &&
        ["style", "class"].includes(mutationRecord.attributeName)
      ) {
        checkHeightChange(); // checking element's class and style changes
      }
    });
  });
  mutationObserver.observe(element, { attributes: true, attributeFilter: ['style', 'class'] });

  // track fonts loading
  if (document.fonts) {
    document.fonts.onloadingdone = checkHeightChange
  }

  // track images loading inside of element
  var images = document.querySelectorAll('img');
  Promise.all(
    [...images].map(image =>
      new Promise(resolve => {
        function handleLoaded() {
          image.removeEventListener('load', handleLoaded)
          image.removeEventListener('error', handleLoaded)
          resolve()
        }
        if (image.complete) resolve();
        else {
          image.addEventListener('load', handleLoaded)
          image.addEventListener('error', handleLoaded)
        }
      })
    )
  ).then(() => { checkHeightChange() })

  // check height changes immediately
  checkHeightChange();
}
