import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["main", "nested"]

  uncheckNested(event) {
    let parentElm = event.target

    if(!parentElm.checked) {
      this.nestedTargets.forEach((elm) => elm.checked = false)
    }
  }

  checkMain(event) {
    let nestedElm = event.target

    if(nestedElm.checked) {
      this.mainTarget.checked = true
    }
  }
}
