import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', this.onSubmit.bind(this))
  }

  onSubmit(event) {
    let button = event.submitter
    if( button && button.dataset.href ) {
      window.open(button.dataset.href, '_blank')
    }
  }
}
