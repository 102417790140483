import { Controller } from "@hotwired/stimulus"
import moment from 'moment';

// options for daterangepicker
// datepicker-range-enabled
// datepicker-timepicker
// datepicker-format
// datepicker-start-date
// datepicker-end-date
// datepicker-min-date
// datepicker-skip-min-date

export default class extends Controller {
  async connect() {
    await import('daterangepicker');

    this.element.autocomplete = 'off'
    const $element = $(this.element)

    // check if in modal - notify daterangepicker that parentEl is modal (timeselect disappear bug in iOS Safari)
    let parentEl = false
    if($element.closest('#remote-modal').length > 0) {
      parentEl = '#remote-modal'
    }
    $element.daterangepicker(this.__pickerOptions(parentEl))

    if(this.data.has('range-enabled')) {
      $element.on('apply.daterangepicker', function(ev, picker) {
        this.value = `${picker.startDate.format(picker.locale.format)} - ${picker.endDate.format(picker.locale.format)}`
        // daterangepicker events are not bubled up neither jQuery events, nor native "change" events for inputs
        // raise it manually, but with little difference to avoid recursion
        $element.trigger('daterangepicker:changed')
      })
    } else {
      $element.on('apply.daterangepicker', function(ev, picker) {
        this.value = picker.startDate.format(picker.locale.format)
        // daterangepicker events are not bubled up neither jQuery events, nor native "change" events for inputs
        // raise it manually, but with little difference to avoid recursion
        $element.trigger('daterangepicker:changed')
      })
    }

    $element.on('cancel.daterangepicker', function(ev, picker) {
      this.value = ''
    })
  }

  get format() {
    let format = null

    if(this.data.has('format')) {
      format = this.data.get('format')
    } else if(this.data.has('timepicker')) {
      format = 'MMM D, YYYY hh:mm A'
    } else {
      format = 'MMM D, YYYY'
    }

    return format
  }

  __pickerOptions(parentEl) {
    let options = {}

    if(this.data.has('range-enabled')) {
      options = this.__optionsForDaterangePicker()
    } else {
      options = this.__optionsForDatePicker()
    }

    if(this.data.has('timepicker')) {
      options.timePicker = true
    }

    if(this.data.has('skip-min-date')){
      options.minDate = undefined;
    }

    if(this.data.has('min-date')){
      options.minDate = new Date(this.data.get('min-date'));
    }

    if(parentEl) {
      options.parentEl = parentEl
    }

    return options
  }

  __optionsForDatePicker() {
    return {
      autoUpdateInput: false,
      minDate: new Date(),
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: this.format
      }
    }
  }

  __optionsForDaterangePicker() {
    let startDate = this.data.get('start-date')
    let endDate = this.data.get('end-date')

    if(!startDate) {
      startDate = moment().add({days: 1}).startOf('hour')
      endDate = moment().add({days: 1, hours: 1}).startOf('hour')
    }

    let options = {
      autoUpdateInput: false,
      minDate: moment().add(1, 'hour').startOf('hour').toDate(),
      startDate: startDate,
      endDate: endDate,
      locale: {
        format: this.format
      }
    }

    if(this.data.has('skip-min-date')){
      options.minDate = undefined;
    }

    if(this.data.has('min-date')){
      options.minDate = new Date(this.data.get('min-date'));
    }

    return options;
  }
}
