import moment from 'moment'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    amountPaid: {type: Number, default: 0.0},
    tipAmountPaid: {type: Number, default: 0.0},
    currency: { type: String, default: 'USD' }
  }

  static targets = ["refundType", "refundedItem", "amount", "tips", "refundTotal"]

  initialize() {
    this.currencyFormatter = Intl.NumberFormat('en', {style: 'currency', currency: this.currencyValue})

    this.$element = $(this.element)

    this.$element.on('change', ':input:not(:button,.autosubmit-disable):visible,:input.autosubmit', this.__recalculateTotal.bind(this))

    // update total on tabs switching
    this.refundTypeTarget.addEventListener('change', this.__recalculateTotal.bind(this))
  }

  __recalculateTotal() {
    let amount = 0

    if( this.refundTypeTarget.value == 'amount') {
      amount = parseFloat(this.amountTarget.value.replace(/,/g, ''))
    } else {
      this.refundedItemTargets.forEach((el) => {
        if( el.checked ) {
          amount += parseFloat(el.dataset.value)
        }
      })
    }

    if( this.hasTipsTarget && this.tipsTarget.checked ) {
      amount += this.tipAmountPaidValue
    }

    this.refundTotalTarget.innerHTML = this.currencyFormatter.format(amount)
  }
}
