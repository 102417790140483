import moment from "moment-timezone";
import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = [
    "list",
    "lineItem",
    "serviceSelector",
    "user",
    "time",
    "date",
    "startAt",
    "duration",
    "amount",
  ];

  connect() {
    this.element["controller"] = this;

    $(this.timeTarget).selectize({ onChange: this.timeChanged.bind(this) });

    this.initTime();
    this.setStartAt();

    Sortable.create(this.listTarget, {});
  }

  disconnect() {
    Sortable.get(this.listTarget).destroy()
  }

  onChangeService(e) {
    if ($(this.listTarget).find("#item-" + e.detail.value)[0]) {
      Notify.error("This service is already added");
      return;
    } else {
      $(this.listTarget).append(e.detail.content);
    }

    this.calculateDurationAndAmount();
  }

  onDeleteService(e) {
    // hide closest tr and set _destroy to 1
    $(e.currentTarget).closest("tr").addClass('d-none').find("input[name*='_destroy']").val(1);

    const event = new CustomEvent("appointment-form-item.service-changed");
    window.dispatchEvent(event);

    this.calculateDurationAndAmount();
  }

  calculateDurationAndAmount() {
    let duration = 0;
    let amount = 0.0;

    this.lineItemTargets.forEach((lineItem) => {
      if(lineItem.classList.contains('d-none')) return;

      duration += parseInt(lineItem.dataset.duration);
      amount += parseFloat(lineItem.dataset.amount);
    });

    this.durationTarget.value = this.durationToTime(duration);
    this.amountTarget.value = amount.toFixed(2);
    this.amountTarget.dispatchEvent(new Event("change", { bubbles: true }));
  }

  durationToTime(duration) {
    const hours = Math.floor(duration / 60);
    const minutes = duration % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  }

  dateChanged(e) {
    this.setStartAt();
  }

  timeChanged(e) {
    this.setStartAt();
  }

  setStartAt() {
    this.startAtTarget.value = `${this.getDate()} ${this.timeTarget.value}`;
  }

  get startAt() {
    return moment(this.startAtTarget.value, "YYYY-MM-DD hh:mm A");
  }

  get endAt() {
    return this.startAt.add(
      (parseInt(this.duration) || 0) + this.bufferTime,
      "minutes"
    );
  }

  get duration() {
    const [hours, minutes] = this.durationTarget.value.split(":");
    return parseInt(hours) * 60 + parseInt(minutes);
  }

  get bufferTime() {
    // get buffer time from last line item
    if (this.lineItemTargets.length > 0) {
      return parseInt(this.lineItemTargets[this.lineItemTargets.length - 1].dataset.bufferTime);
    }

    return 0;
  }

  getCustomerId() {
    return $("#booking_customer_id").val();
  }

  getDate() {
    return $("#booking_start_at").val();
  }

  isMobile() {
    for (const lineItem of this.lineItemTargets) {
      if(lineItem.classList.contains('d-none')) continue;

      if (lineItem.dataset.mobile === "true") return true;
    }

    return false;
  }

  // set a time based on previous service
  initTime() {
    let prevService = $(this.element).prevAll(".card").first()[0];

    if (prevService) {
      let prevStartAt = prevService.controller.startAt;
      let prevEndAt = prevService.controller.endAt;
      let newStartAt = prevEndAt;

      if (newStartAt.minute() % 5 != 0) {
        newStartAt.add(5 - (newStartAt.minute() % 5), "minutes");
      }
      // All times should be within one day
      if (prevStartAt.date() != newStartAt.date()) {
        newStartAt = prevStartAt;
      }

      this.timeTarget.selectize.setValue(newStartAt.format("hh:mm A"), false);
    }
  }
}
