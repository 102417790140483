import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var myDefaultWhiteList = $.fn.selectpicker.Constructor.DEFAULTS.whiteList;
    myDefaultWhiteList.span = ['data-controller', 'data-booking--product-addon-target', 'data-action'];;

    $(this.element).selectpicker({
      container: '.booking-page',
      whiteList: myDefaultWhiteList,
      sanitize: false
    });

    // Initialize product addon controllers after selectpicker renders options
    $(this.element).on('shown.bs.select', () => {
      const dropdown = document.querySelector('.dropdown-menu.inner')
      if (dropdown) {
        const controllers = dropdown.querySelectorAll('[data-controller="booking--product-addon"]')
        controllers.forEach(element => {
          application.register("booking--product-addon", ProductAddonController)
        })
      }
    })
  }
}
