import moment from 'moment'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    currency: { type: String, default: 'USD' }
  }

  static targets = ["durationType",
                    "durationYears", "yearVisitsCount", "visitsCount",
                    "syncAmountsMode",
                    "totalAmount",
                    "weeklyAmount", "biweeklyAmount",
                    "monthlyAmount", "sixMonthsAmount",
                    "quartelyAmount", "annualAmount",
                    "oneTimeAmount", "visitAmount",
                    "yearVisitsContainer", "totalContainer", "subtotalContainer", "oneTimeContainer"]

  initialize() {
    this.currencyFormatter = Intl.NumberFormat('en', {style: 'currency', currency: this.currencyValue})
    this.element.querySelectorAll('.input-currency input').forEach( input => this.__updateSubtotal(input) )
  }

  get durationType() { return this.durationTypeTargets.find(o => o.checked).value }
  get syncAmountsMode() { return this.syncAmountsModeTarget.checked }

  setDurationType() {
    switch(this.durationType) {
    case 'infinite':
      this.__toggleTotalContainer(false) // hide
      this.oneTimeContainerTarget.classList.add('d-none') // hide
      this.yearVisitsContainerTarget.classList.remove('d-none') // show
      break
    case 'years':
      this.__toggleTotalContainer(true)  // show
      this.oneTimeContainerTarget.classList.remove('d-none') // show
      this.yearVisitsContainerTarget.classList.remove('d-none') // show
      break
    case 'visits':
      this.__toggleTotalContainer(true)  // show
      this.oneTimeContainerTarget.classList.remove('d-none') // show
      this.yearVisitsContainerTarget.classList.add('d-none') // hide
      break
    }
  }

  updateYears() {
    let years = parseInt(this.durationYearsTarget.value)
    if( !isNaN(years) && years > 1 ) {
      $("label[for='subscription_plan_total']").html(`Total price for ${years} years`)
    } else {
      $("label[for='subscription_plan_total']").html("Total price")
    }

    if( isFinite(years) ) {
      this.recalculate()
    }
  }

  updateVisits() {
    $("label[for='subscription_plan_total']").html("Total price")

    let visitsCount = parseInt(this.visitsCountTarget.value)
    let visitAmount = this.__inputValueToFloat(this.visitAmountTarget.value)
    if( isFinite(visitsCount) && isFinite(visitAmount) ) {
      this.recalculate()
    }
  }

  toggleAmountsMode() {
    this.__toggleTotalContainer(this.syncAmountsMode)

    let isShowSubtotals = !this.syncAmountsMode
    this.subtotalContainerTargets.forEach(container => {
      if( isShowSubtotals ) {
        container.classList.remove('d-none')
      } else {
        container.classList.add('d-none')
      }
    })
  }

  updateAmounts(event) {
    if( this.syncAmountsMode ) {
      this.__syncAmounts(event.target)
    } else {
      this.__updateSubtotal(event.target)
    }
  }

  recalculate() {
    if( !this.syncAmountsMode ) return

    // prefer to update all amounts based on total
    if( this.durationType != 'visits' || this.__inputValueToFloat(this.totalAmountTarget.value) > 0 ) {
      this.__syncAmounts(this.totalAmountTarget)
    } else {
      this.__syncAmounts(this.visitAmountTarget)
    }
  }

  __toggleTotalContainer(isShow) {
    if( isShow && this.syncAmountsMode ) {
      this.totalContainerTarget.classList.remove('d-none') // show
    } else {
      this.totalContainerTarget.classList.add('d-none') // hide
    }
  }

  __syncAmounts(changedInput) {
    let totalAmount = 0
    let weeklyAmount = 0
    let biweeklyAmount = 0
    let monthlyAmount = 0
    let sixMonthsAmount = 0
    let quartelyAmount = 0
    let annualAmount = 0
    let oneTimeAmount = 0

    let visitAmount = 0
    // do not reset it, because it depends only on visit count and shouldn't be recalculated on dates changes
    if( this.durationType != 'visits' ) {
      visitAmount = this.__inputValueToFloat(this.visitAmountTarget.value)
    }

    let durationYears = parseInt(this.durationYearsTarget.value)
    if( isNaN(durationYears) ) durationYears = 1
    let weeksCount = moment().isoWeeksInYear()
    let biweeksCount = weeksCount / 2
    let yearVisitsCount = parseInt(this.yearVisitsCountTarget.value)
    let visitsCount = parseInt(this.visitsCountTarget.value)

    switch (changedInput.id) {
      case 'subscription_plan_total':
        totalAmount = this.__inputValueToFloat(changedInput.value)
        if( isNaN(totalAmount) ) return;
        annualAmount = totalAmount / durationYears
        break
      case 'subscription_plan_weekly_amount':
        weeklyAmount = this.__inputValueToFloat(changedInput.value)
        annualAmount = weeklyAmount * weeksCount
        break
      case 'subscription_plan_biweekly_amount':
        biweeklyAmount = this.__inputValueToFloat(changedInput.value)
        annualAmount = biweeklyAmount * biweeksCount
        break
      case 'subscription_plan_monthly_amount':
        monthlyAmount = this.__inputValueToFloat(changedInput.value)
        annualAmount = monthlyAmount * 12
        break
      case 'subscription_plan_six_months_amount':
        sixMonthsAmount = this.__inputValueToFloat(changedInput.value)
        annualAmount = sixMonthsAmount * 2
        break
      case 'subscription_plan_quartely_amount':
        quartelyAmount = this.__inputValueToFloat(changedInput.value)
        annualAmount = quartelyAmount * 4
        break
      case 'subscription_plan_annual_amount':
        annualAmount = this.__inputValueToFloat(changedInput.value)
        break
      case 'subscription_plan_one_time_amount':
        oneTimeAmount = this.__inputValueToFloat(changedInput.value)
        annualAmount = oneTimeAmount / durationYears
        break
      case 'subscription_plan_visit_amount':
        visitAmount = this.__inputValueToFloat(changedInput.value)

        if( this.durationType == 'visits' && isFinite(visitsCount) ) { // visitsCount is set, so total and one-time can be set
          totalAmount = visitAmount * visitsCount
          annualAmount = totalAmount
        } else if( isFinite(yearVisitsCount) ) {
          annualAmount = visitAmount * yearVisitsCount
        }

        break
    }

    totalAmount = annualAmount * durationYears // durationYears is always set, 1 as default
    oneTimeAmount = totalAmount

    weeklyAmount = annualAmount / weeksCount
    biweeklyAmount = annualAmount / biweeksCount
    monthlyAmount = annualAmount / 12
    sixMonthsAmount = annualAmount / 2
    quartelyAmount = annualAmount / 4

    if(isFinite(visitsCount)) visitAmount = totalAmount / visitsCount
    if(isFinite(yearVisitsCount)) visitAmount = annualAmount / yearVisitsCount

    this.totalAmountTarget.value = totalAmount.toFixed(2)
    this.weeklyAmountTarget.value = weeklyAmount.toFixed(2)
    this.biweeklyAmountTarget.value = biweeklyAmount.toFixed(2)
    this.monthlyAmountTarget.value = monthlyAmount.toFixed(2)
    this.sixMonthsAmountTarget.value = sixMonthsAmount.toFixed(2)
    this.quartelyAmountTarget.value = quartelyAmount.toFixed(2)
    this.annualAmountTarget.value = annualAmount.toFixed(2)
    this.oneTimeAmountTarget.value = oneTimeAmount.toFixed(2)
    this.visitAmountTarget.value = visitAmount.toFixed(2)
  }

  __updateSubtotal(changedInput) {
    let durationYears = parseInt(this.durationYearsTarget.value)
    if( isNaN(durationYears) ) durationYears = 1
    let weeksCount = moment().isoWeeksInYear()

    let subtotal = 0

    switch (changedInput.id) {
      case 'subscription_plan_weekly_amount':
        let weeklyAmount = this.__inputValueToFloat(changedInput.value)
        subtotal = weeklyAmount * weeksCount * durationYears
        break
      case 'subscription_plan_biweekly_amount':
        let biweeksCount = weeksCount / 2
        let biweeklyAmount = this.__inputValueToFloat(changedInput.value)
        subtotal = biweeklyAmount * biweeksCount * durationYears
        break
      case 'subscription_plan_monthly_amount':
        let monthlyAmount = this.__inputValueToFloat(changedInput.value)
        subtotal = monthlyAmount * 12 * durationYears
        break
      case 'subscription_plan_six_months_amount':
        let sixMonthsAmount = this.__inputValueToFloat(changedInput.value)
        subtotal = sixMonthsAmount * 2 * durationYears
        break
      case 'subscription_plan_quartely_amount':
        let quartelyAmount = this.__inputValueToFloat(changedInput.value)
        subtotal = quartelyAmount * 4 * durationYears
        break
      case 'subscription_plan_annual_amount':
        let annualAmount = this.__inputValueToFloat(changedInput.value)
        subtotal = annualAmount * durationYears
        break
      case 'subscription_plan_one_time_amount':
        subtotal = this.__inputValueToFloat(changedInput.value)
        break
      case 'subscription_plan_visit_amount':
        let yearVisitsCount = parseInt(this.yearVisitsCountTarget.value)
        let visitsCount = parseInt(this.visitsCountTarget.value)
        let visitAmount = this.__inputValueToFloat(changedInput.value)

        if( this.durationType == 'visits' && isFinite(visitsCount) ) { // visitsCount is set, so total and one-time can be set
          subtotal = visitAmount * visitsCount
        } else if( isFinite(yearVisitsCount) ) {
          subtotal = visitAmount * yearVisitsCount
        }

        break
      default:
        return // do nothing
    }

    let subtotalContainer = $(changedInput).parents('td').next('td.subtotal')
    subtotalContainer.html(`TOTAL: ${this.currencyFormatter.format(subtotal.toFixed(2))}`)
  }

  __inputValueToFloat(value) {
    return parseFloat(value.replace(/,/g, ''))
  }
}
