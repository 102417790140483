import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["targetDropdown"]

  connect() {
    this.targetDropdownTargets.forEach( dropdown => dropdown.addEventListener('change', this.__onDropdownChange.bind(this)) )
  }

  __onDropdownChange(event) {
    let dropdown = event.target
    let value = dropdown.value
    // submit form and if everything is ok open the nested modal with target creation
    if( value && (value.startsWith("/") || value.startsWith("http")) ) {
      let formData = new FormData(this.element)
      formData.append("keep_dialog_open", true)

      fetch(this.element.action, {
        method: 'PATCH',
        body: formData,
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      }).then(response => response.ok ? response.text() : Promise.reject(response.statusText))
        .then(html => {
          if( html.length == 0) { // everything is ok, empty response
            RemoteModal.show('visit-target-form', value, { nested: true })
          } else {
            eval(html)
          }
        })
        .catch(error => console.log(error))
    }
  }
}
