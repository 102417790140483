import { Calendar as FullCalendar } from '@fullcalendar/core';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin, { ResourceTimeGridView } from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import CalendarEvent from './calendar/calendar-event';
import CalendarSettings from './calendar/calendar-settings';
import CalendarFilters from './calendar/calendar-filters';
import CalendarBusinessHours from './calendar/calendar-business-hours';
import tippy from 'tippy.js'; // tooltip
import {hideAll} from 'tippy.js'; // tooltip
import moment from 'moment-timezone';

// import Rails from '@rails/ujs';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.controller = this
    this.dateFormat = "YYYY-MM-DD HH:mm";
    this.el = document.getElementById('calendar-wrapper');
    this.allowTooltip = true;
    this.contextMenuTmpl = document.getElementById('template-context-menu')
    this.isAppointmentMode = window.calendarData.appointment_mode;
    this.isJobMode = window.calendarData.job_mode;
    this.isClassMode = window.calendarData.class_mode;
    this.businessHours = new CalendarBusinessHours(window.calendarData.businessHours);

    let eventSources = []
    if(this.isAppointmentMode){
      eventSources.push({ url: '/appointments.json', eventDataTransform: this.transformEventData })
    }
    if(this.isClassMode){
      eventSources.push({ url: '/class_sessions.json', eventDataTransform: this.transformEventData })
    }
    if(this.isJobMode){
      eventSources.push(
          {url: '/jobs.json', eventDataTransform: this.transformEventData},
          {url: '/estimates.json', eventDataTransform: this.transformEventData})
    }
    eventSources.push(
        {url: '/events.json', eventDataTransform: this.transformEventData},
        {url: '/tasks.json', eventDataTransform: this.transformEventData},
        {url: '/calendar/timeoffs.json', eventDataTransform: this.transformEventData})


    // filters
    this.filters = new CalendarFilters({
      onFilterUser: () => {
        this.settings.setFilteredUsers(this.filters.getFilteredUsers())
        this.calendar.rerenderEvents()
        this.calendar.refetchResources()
      },
      onFilterCustomer: () => {
        this.settings.setFilteredCustomersWithLabels(this.filters.getFilteredCustomersWithLabels())
        this.calendar.rerenderEvents()
        this.calendar.refetchResources()
      },
      onFilterDate: (date) => {
        this.calendar.gotoDate(date)
      }
    });

    // drag unscheduled event
    if(this.isJobMode) {
      new Draggable(document.getElementById('calendar-unscheduled'), {
        itemSelector: '.fc-event',
        eventData: (eventEl) => {
          return this.transformEventData($(eventEl).data('event').data)
        }
      });
    }

    this.calendar = new FullCalendar(this.el, {
      schedulerLicenseKey: window.calendarData.slk,
      plugins: [dayGridPlugin, timeGridPlugin, resourceTimeGridPlugin,
                resourceTimelinePlugin, bootstrapPlugin, interactionPlugin],
      defaultView: 'resourceTimelineTenDay',
      resourceLabelText: 'Employee',
      themeSystem: 'bootstrap',
      allDaySlot: false,
      editable: true,
      nowIndicator: true,
      now: window.calendarData.currentTime,
      header: false,
      droppable: true, // this allows things to be dropped onto the calendar
      slotLabelFormat: (slot) => moment(slot.date).format('h a'),
      views: {
        // Dispatch
        resourceTimelineTenDay: {
          type: 'resourceTimeline',
          duration: { days: 1 },
          resourceAreaWidth: '15%',
          // slotDuration: '00:30:00',
          slotWidth: 75,
          // slotLabelFormat: [
          //   { month: 'short', day: 'numeric', weekday: 'short' }, // top level of text
          //   { hour: 'numeric', meridiem: 'short' } // lower level of text
          // ],
        },
        // Week
        timeGridWeek: {
          columnHeaderHtml: (date) => {
            return `<span class='fc-header--week-dayname'>${moment(date).format('ddd')}</span><br/><span class='fc-header--week-day'>${moment(date).format('D')}</span>`;
          }
        }
      },

      businessHours: this.businessHours.businessHours,

      datesRender: (info) => {
        this.settings.setStartDate(info.view.currentStart)
        this.filters.setDate(info.view.currentStart, info.view.type)
      },

      resourceOrder: 'name',
      resources: (fetchInfo, successCallback, failureCallback) => {
        // render only filtered resources(users)
        if(this.filters.filteredUsers.length == 0) {
          successCallback(window.calendarData.users);
        } else {
          var result = window.calendarData.users.filter( user => this.filters.filteredUsers.indexOf(user.id) != -1)
          successCallback(result);
        }
      },
      resourceRender: (info) => {
        if(info.view.type === "resourceTimelineTenDay") {
          $(info.el).find('.fc-cell-content').append(`<span class="d-block">${info.resource.extendedProps.avatar}</span>`)
        } else if(info.view.type === "resourceTimeGridDay") {
          info.el.innerHTML = `<div class="resource-inner"><div>${info.resource.extendedProps.avatar}</div><div class="resource-title">${info.resource.title}</div><div class="flex-grow-1"></div><div id="resource-${info.resource.id}-events-counter" class="resource-events-counter d-none"></div>`
        }
      },

      // https://fullcalendar.io/docs/v3/event-source-object#options
      eventSources: eventSources,

      eventSourceSuccess: (content, xhr) => {
        return content.data
      },
      eventRender: this.eventRender.bind(this),
      loading: (isLoading) => {
        if(!isLoading) {
          this.scrollToMinTime()
        }
      },
      eventDrop: this.eventChanged.bind(this),
      eventResize: this.eventChanged.bind(this),
      eventDragStart: (info) => {
        window.clearTimeout(this.allowTooltipTimeout)
        this.allowTooltip = false
      },
      eventDragStop: (info) => {
        window.clearTimeout(this.allowTooltipTimeout)
        this.allowTooltipTimeout = window.setTimeout(() => {
          this.allowTooltip = true;
        }, 320) // allow to show tooltip after tooltip delay (300) to prevent js error
      },
      eventResizeStart: (info) => { this.allowTooltip = false },
      eventResizeStop: (info) => { this.allowTooltip = true },
      dateClick: this.showContextMenu.bind(this),

      // dispatch && schedule ui move an event between employees
      eventReceive: (info) => {
        let options = {}
        let resource = info.event.getResources()[0]
        if(resource) options.new_user_id = resource.id;

        this.scheduleRequest(info, options, (info) => {
          info.draggedEl.parentNode.removeChild(info.draggedEl);

          if(this.isJobMode) {
            let leftEvents = $('#calendar-unscheduled-events').find('.fc-event').length;
            $('#calendar-unscheduled-btn').next().find('.badge').text(leftEvents)
          }
        })
      },
    });

    if(this.isJobMode) {
      $('#calendar-unscheduled-btn').on('click', this.toggleUnsheduledSidebar.bind(this))
    }

    $("#calendar-select-view").on('change', (event) => { this.settings.setView(event.target.value) })

    $('#calendar-today-btn').on('click', this.changeDate.bind(this, 'today'))
    $('#calendar-prev-btn').on('click', this.changeDate.bind(this, 'prev'))
    $('#calendar-next-btn').on('click', this.changeDate.bind(this, 'next'))

    // hide all tooltips on context menu item click and link inside a tooltip
    $(document).on('click', '.calendar-context-menu .dropdown-item, .info-content a', () => hideAll())
    // allow panning by mouse
    this.enablePanningByMouse()

    // restore calendar's view, current date, filters
    this.settings = new CalendarSettings(this.calendar)
    this.settings.restore();

    this.filters.calendar = this.calendar;
    this.filters.getFilteredUsers()
    this.filters.getFilteredCustomers()
    this.calendar.refetchResources()
    this.calendar.render()
  }

  // scroll a view to the min time after calendar's data load
  scrollToMinTime() {
    if(this.calendar.view.type === 'dayGridMonth')
      return

    let eventsMinTime = this.getEventsMinTime()
    let businessHoursScrollTime = this.businessHours.minTime

    if(this.calendar.view.type === 'timeGridDay') {
      businessHoursScrollTime = this.businessHours.minTimeByDay(this.calendar.view.currentStart.getDay())
    }

    let scrollByEvents = eventsMinTime && Number(eventsMinTime.replaceAll(':', '')) < Number(businessHoursScrollTime.replaceAll(':', ''))
    this.calendar.scrollToTime(scrollByEvents ? eventsMinTime : businessHoursScrollTime)
  }

  getEventsMinTime() {
    let minTime = undefined
    this.calendar.getEvents().forEach((el) => {
      if (el.start >= this.calendar.view.activeStart && el.start < this.calendar.view.activeEnd && el.extendedProps.type !== 'timeoff') {
        let eventStartNormalized = this.calendar.view.activeStart
        eventStartNormalized.setHours(el.start.getHours());
        eventStartNormalized.setMinutes(el.start.getMinutes());
        eventStartNormalized.setSeconds(el.start.getSeconds());
        if (!minTime || minTime > eventStartNormalized) {
          minTime = eventStartNormalized
        }
      }
    })

    return minTime ? moment(minTime).format('HH:mm:ss') : undefined
  }

  eventChanged(info) {
    // dispatch && shedule ui move an event between employees
    let options = {}
    if(info.newResource) options.new_user_id = info.newResource.id;
    if(info.oldResource) options.old_user_id = info.oldResource.id;

    this.scheduleRequest(info, options)
  }

  showContextMenu(info) {
    const x = info.jsEvent.clientX;
    const y = info.jsEvent.clientY;

    let startAt = moment(info.date)
    let params = {
      start_at: startAt.format(this.dateFormat),
      end_at: startAt.add(1, 'hour').format(this.dateFormat)
    }

    if(info.resource) params.user_id = info.resource.id

    let query = $.param(params);
    let contextMenu = $(this.contextMenuTmpl).clone();
    $(contextMenu).find('.dropdown-item').each(function(){
      $(this).attr('href', $(this).attr('href') + `?${query}`)
    });

    tippy(info.jsEvent.target, {
      getReferenceClientRect: () => ({
        width: 0,
        height: 0,
        top: y,
        bottom: y,
        left: x,
        right: x
      }),
      theme: 'calendar',
      duration: 100,
      delay: [100, 0],
      interactive: true,
      allowHTML: true,
      arrow: false,
      content: contextMenu.html(),
      appendTo: () => document.body,
      onHidden: function(instance){
        instance.destroy();
      }
    }).show();
  }

  eventRender(info) {
    let event = new CalendarEvent(this, info)

    if(event.isVisible(this.filters.filteredUsers, this.filters.filteredCustomers)) {
      event.buildTooltip()
      event.render()
    } else {
      return false
    }
  }

  toggleUnsheduledSidebar() {
    $('#calendar-sidebar').removeClass('open-filters').toggleClass('open-unscheduled')
  }

  changeDate(method) {
    this.calendar[method]()
  }

  // save change event schedule on server
  scheduleRequest(info, options, onDoneCallback) {
    options.start_at = moment(info.event.start).format(this.dateFormat)
    options.end_at = info.event.end ? moment(info.event.end).format(this.dateFormat) : moment(info.event.start).add(1, 'hour').format(this.dateFormat)
    options.source = 'calendar'

    $.ajax(info.event.extendedProps.links.schedule, {
      type: 'PATCH',
      dataType: 'json',
      data: options
    })
    .done((result)=> {
      info.event.setExtendedProp('attr', result.data.attributes)

      if(onDoneCallback) onDoneCallback(info);
    })
    .fail(() => {
      Notify.error('Something went wrong, try again later')
    });
  }

  // transform source event data to fullcalendar event data
  transformEventData(data) {
    let attr = data.attributes;
    let eventData = {
      type: data.type,
      title: attr.title,
      start: attr.start_at,
      end: attr.end_at,
      attr: attr,
      url: data.links.self,
      links: data.links,
      resourceIds: attr.user_ids
    };

    if(data.links.self) eventData.url = data.links.self;

    return eventData;
  }

  refetch() {
    this.calendar.refetchEvents()
    if(this.isJobMode) {
      this.reloadUnscheduled()
    }
  }

  reloadUnscheduled() {
    document.getElementById('calendar-unscheduled-events').controller.reload(function () {
      let unscheduledCount = $('#calendar-unscheduled-events .fc-event').length
      $('#calendar-unscheduled-btn').next().find('.badge').text(unscheduledCount > 0 ? unscheduledCount : '')
    })
  }

  enablePanningByMouse() {
    let pageX_Drag;
    let disableDragSelector = '.fc-event';

    $(document).on('mousedown', '.fc-scroller', function (e) {
      // prevent drag from happening if we click on certain things
      let dragDisabledElms = $(e.target).parents(disableDragSelector).addBack(disableDragSelector);
      if (dragDisabledElms.length) return;

      pageX_Drag = e.pageX;

      $(window).off("mousemove", __mouseMove_Drag);
      $(window).on("mousemove", __mouseMove_Drag);
    });

    $(window).on('mouseup', __mouseUp_Drag);

    function __mouseUp_Drag() {
      $(window).off("mousemove", __mouseMove_Drag);
    }

    function __mouseMove_Drag(evt) {
      let offsetX = pageX_Drag - evt.pageX;

      $('.fc-scroller').each(function () {
        let scroller = $(this);
        let newX = scroller.scrollLeft() + offsetX;

        scroller.scrollLeft(newX);
      });

      pageX_Drag = evt.pageX;
    }
  }
}
