import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'subscriptionPaymentBtn' ]

  choosePaymentOption(event) {
    let planId = event.target.dataset.subscriptionPlanId

    this.subscriptionPaymentBtnTargets.forEach(btn => {
      if(btn.dataset.subscriptionPlanId == planId) {
        btn.classList.remove("d-none")
      } else {
        btn.classList.add("d-none")
      }
    })
  }
}
