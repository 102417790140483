import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    text: String,
    templateId: String,
    interactive: Boolean,
    placement: String
  }

  connect() {
    let content = ""
    if( this.templateIdValue ) {
      content = document.getElementById(this.templateIdValue).innerHTML
    } else {
      content = this.textValue
    }

    tippy(this.element, {
      content: content,
      allowHTML: true,
      interactive: this.interactiveValue,
      placement: this.placementValue || "top"
    })
  }

  disconnect() {
    this.element._tippy?.destroy();
  }
}
