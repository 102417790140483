import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["classService", "user", "date", "time", "duration", "description", "seats", "amount", "submit"]

  connect() {
    this.datePicker = flatpickr('#class_session_date_part');
    if (!this.dateTarget.value) this.datePicker.setDate(moment().format());

    $(this.timeTarget).selectize();
  }

  onChangeClassService(e) {
    this.durationTarget.value = e.detail.duration;
    this.seatsTarget.value = e.detail.seats;
    this.amountTarget.value = e.detail.price;
    this.descriptionTarget.value = e.detail.description;

    // this.enableFormFields();
  }

  enableFormFields() {
    if(this.classServiceTarget.value && this.userTarget.value) {
      this.dateTarget.removeAttribute('disabled')
      this.timeTarget.removeAttribute('disabled')
    } else {
      this.dateTarget.setAttribute('disabled', true)
      this.timeTarget.setAttribute('disabled', true)
    }

    // if(this.dateTarget.value && this.timeTarget.value) {
    //   this.submitTarget.removeAttribute('disabled')
    // } else {
    //   this.submitTarget.setAttribute('disabled', true)
    // }
  }
}
