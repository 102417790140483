// Using selectpicker for multiple select dropdown to select multiple services
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    $(this.element).selectpicker({
      liveSearch: true,
      selectedTextFormat: "count > 3",
    });
  }

  disconnect() {
    // $(this.element).selectpicker('destroy');
  }
}
