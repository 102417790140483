import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  rowSelected(event){
    this.updateSelectAllState()
    this.showBulkActions()
  }

  selectAll(event) {
    // check/uncheck all "Select All" buttons
    const checked = $(event.target).is(':checked');
    $('.select-all').prop('checked', checked);
    // check/uncheck all selectable objects
    $('input[type=checkbox].selectable').prop('checked', checked);

    this.showBulkActions();
  }

  updateSelectAllState(){
    const selectableRows = $('input[type=checkbox].selectable');
    const selectedRows = selectableRows.filter(':checked');
    $('.select-all').prop('checked', selectableRows.length == selectedRows.length);
  }

  showBulkActions() {
    var selectedRows = $('input[type=checkbox].selectable:checked');
    $('.bulk-actions').toggle(selectedRows.length > 0);
    $('.selected-count .counter').text(selectedRows.length);
    var columnsNamesRow = $('.bulk-actions').parent().find('tr:last-child');
    columnsNamesRow.toggle(selectedRows.length == 0);
    // show selected count
    $('.selected-count .counter').text(selectedRows.length);

    // toggle invoices stats in invoices table
    document.querySelector('.invoices-stats')?.classList.toggle('d-none', selectedRows.length > 0);
  }

  // make remote ajax call, like data-remote=true
  // data-bulk-confirm - because data-confirm captured by default rails handler
  // data-method
  doAction(event) {
    event.preventDefault();
    const confirmText  = $(event.target).data('bulk-confirm');
    const method  = $(event.target).data('method');
    if (!confirmText || Rails.confirm(confirmText, event.target)) {
      const request = {
        url: $(event.target).attr('href'),
        type: "POST",
        data: {
          "object_ids": this.getSelectedObjects()
        }
      };
      method && (request['data']['_method'] = method);

      $.ajax(request);
    }
  }

  showModal(event) {
    event.preventDefault();
    var ids = this.getSelectedObjects();
    if (ids.length > 0) {
      var url = $(event.currentTarget).attr('href');
      var target = $(event.currentTarget).data('target');
      RemoteModal.show(target, url + '?' + $.param({ object_ids: ids }));
    }
  }

  getSelectedObjects() {
    var selected_objects = $('input[type=checkbox].selectable:checked');
    var ids = [];
    if (selected_objects.length > 0) {
      ids = $.map(selected_objects, function(o) { return $(o).val() });
    }
    return ids;
  }

}
